/* General Styles */
body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background-color: #f8f9fa;
  color: #333;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, #ddd 1px, transparent 1px),
    linear-gradient(90deg, transparent, #ddd 1px, transparent 1px);
  background-size: 50px 50px;
  z-index: 0;
}

/* Header */
.header {
  text-align: center;
  padding: 1.5rem;
  position: relative;
  z-index: 1;
}

.header-text {
  font-size: 2.5rem;
  font-weight: bold;
  color: #212529;
  position: relative;
  display: inline-block;
  overflow: hidden;
}

/* Content */
.content {
  display: flex;
  flex: 1;
  padding: 2rem;
  gap: 2rem;
  z-index: 1;
}

.text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 2rem;
}

.logo-container {
  margin-bottom: 1rem;
  text-align: center;
}

.logo {
  height: 100px;
  width: auto;
}

.text-section p {
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  line-height: 1.5;
  color: #555;
}

.form {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.email-input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.notify-button {
  padding: 0.75rem 1.5rem;
  background-color: #212529;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.notify-button:hover {
  background-color: #ff7e5f;
}

/* Right Section */
.image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Footer */
footer {
  background-color: #212529;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding: 0.5rem 0;
  font-size: 0.9rem;
  border-top: 1px solid #ddd;
  z-index: 1;
}

.footer-content p {
  margin: 0.2rem 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
    padding: 1rem;
  }

  .text-section {
    padding-right: 0;
    text-align: center;
  }

  .logo {
    height: 80px;
    margin-bottom: 1rem;
  }

  .form {
    flex-direction: column;
  }

  .email-input {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .notify-button {
    width: 100%;
  }

  .image-section {
    margin-top: 1.5rem;
  }
}

@media (max-width: 480px) {
  .header-text {
    font-size: 1.8rem;
  }

  .text-section p {
    font-size: 1rem;
  }

  .footer-content {
    font-size: 0.8rem;
  }
}
